<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "legal_index"
}
</script>

<style scoped>
  .legal { font-size: 12px; max-width: 800px; margin: auto; text-align: justify; padding: 32px 16px }
</style>